import Cookie from 'js.cookie';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import fullScreen from 'lightgallery/plugins/fullscreen'

export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    if (!Cookie.get('alert') && $('#modal').length || $('#modal').hasClass('no-cookie')) {
      $('#modal').show();
      Cookie.remove('alert');
    }
    
    $('.modal .btn-close').click(function() {
      $('#modal').hide();
      Cookie.set('alert', true, {
        expires: 1,
      });
    });
    
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.scrollup').fadeIn();
      } else {
        $('.scrollup').fadeOut();
      }
    });
    
    $('.scrollup').click(function () {
      $('html, body').animate({
        scrollTop: 0,
      }, 600);
      return false;
    });

    // toggles hamburger and nav open and closed states
    var removeClass = true;
    $('.hamburger').click(function () {
      $('.hamburger').toggleClass('is-active');
      $('#sideNav').toggleClass('sideNav-open');
      $('.sideNavBody').toggleClass('sideNavBody-push');
      removeClass = false;
    });

    $('.sideNav').click(function() {
      removeClass = false;
    });

    document.addEventListener('touchstart', function(e) {
      if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
        $('.hamburger').removeClass('is-active');
        $('#sideNav').removeClass('sideNav-open');
        $('.sideNavBody').removeClass('sideNavBody-push');
      }
      removeClass = true;
    }, false);

    lightGallery(document.getElementById('project-gallery'), {
      licenseKey: 'F28B4CA0-283749F1-BBEB75B9-915E2B0B',
      selector: '.gallery-image',
      plugins: [lgZoom, lgThumbnail, fullScreen],
      speed: 500,
      preload: 1,
      fullScreen: true,
      mobileSettings: {
        controls: true,
        showCloseIcon: true,
        download: false,
      },
    });

    if ($('.puppies-carousel').length) {
      $('.puppies-carousel').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        appendArrows: '.puppies-carousel',
        adaptiveHeight: true,
        arrows: true,
        dots: false,
        responsive: [
          {
            breakpoint: 1439,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              appendArrows: '.puppies-carousel',
              arrows: true,
            },
          },
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              appendArrows: '.puppies-panel .slider-nav',
              arrows: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              appendArrows: '.puppies-panel .slider-nav',
              arrows: true,
            },
          },
        ],
      });
    }

    if ($('.litters-carousel').length) {
      $('.litters-carousel').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        appendArrows: '.litters-carousel',
        adaptiveHeight: true,
        arrows: true,
        dots: false,
        responsive: [
          {
            breakpoint: 1439,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              appendArrows: '.litters-carousel',
              arrows: true,
            },
          },
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              appendArrows: '.litters-panel .slider-nav',
              arrows: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              appendArrows: '.litters-panel .slider-nav',
              arrows: true,
            },
          },
        ],
      });
    }

    if ($('.testimonials-carousel').length) {
      $('.testimonials-carousel').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        appendArrows: '.testimonials-panel .slider-nav',
        appendDots: '.testimonials-panel .slider-nav',
        adaptiveHeight: true,
        arrows: true,
        dots: true,
        responsive: [
          {
            breakpoint: 1439,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      });
    }
  },
};
